import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { CreateUserFormData } from '@/components/forms/AddUserForm.tsx';
import { EditUserFormData } from '@/components/forms/EditUserForm.tsx';
import {
  addUser,
  setFilteredNumberOfUsers,
  setParcelsForUser,
  setTotalNumberOfUsers,
  setUpdatedUser,
  setUsers,
  setUsersForDropdown,
} from '@/redux/users/users.slice.ts';
import {
  CreateUserResponse,
  GetParcelsForUserResponse,
  GetUserDropdownResponse,
  GetUsersResponse,
  UpdateUserResponse,
} from '@/redux/users/users.types.ts';
import axiosServer from '@/services/axios.service.ts';

const getFilteredUsers = createAsyncThunk<GetUsersResponse, string>(
  'distributor/get-users',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetUsersResponse>(
        `/distributor/user${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setUsers(axiosResponse.data.content.fetchedUsers));
      dispatch(setTotalNumberOfUsers(axiosResponse.data.content.totalCount));
      dispatch(
        setFilteredNumberOfUsers(axiosResponse.data.content.filteredCount)
      );
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const createUser = createAsyncThunk<CreateUserResponse, CreateUserFormData>(
  'distributor/create-user',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.post<CreateUserResponse>(
        '/distributor/user/create-user',
        reqData
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(addUser(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getUserById = createAsyncThunk<CreateUserResponse, string>(
  'distributor/get-user-by-id',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.get<CreateUserResponse>(
        `/distributor/user/get-one?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const updateUser = createAsyncThunk<UpdateUserResponse, EditUserFormData>(
  'distributor/edit-user',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.put<UpdateUserResponse>(
        '/distributor/user/update',
        reqData
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setUpdatedUser(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getUsersForDropdown = createAsyncThunk<GetUserDropdownResponse, void>(
  'distributor/get-user-by-id',
  async (_reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetUserDropdownResponse>(
        `/distributor/user/get-dropdown`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setUsersForDropdown(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const deleteUser = createAsyncThunk<CreateUserResponse, { userId: string }>(
  'distributor/delete-user',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.delete<CreateUserResponse>(
        `/distributor/user/delete-user?userId=${reqData.userId}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getUsersForParcelCombobox = createAsyncThunk<
  GetUserDropdownResponse,
  void
>('distributor/get-user-parcel-combobox', async (_reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<GetUserDropdownResponse>(
      `/distributor/user/get-parcel-combobox?distributorId=${localStorage.getItem('id')}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setUsersForDropdown(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getParcelsForUser = createAsyncThunk<GetParcelsForUserResponse, string>(
  'distributor/get-parcels-for-user',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetParcelsForUserResponse>(
        `/distributor/user/get-parcels-for-user?userId=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setParcelsForUser(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

export {
  createUser,
  getFilteredUsers,
  getUserById,
  updateUser,
  getUsersForDropdown,
  deleteUser,
  getUsersForParcelCombobox,
  getParcelsForUser,
};
