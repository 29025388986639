import './App.css';

import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AuthenticatedRoutes from '@/components/router/AuthenticatedRoutes.tsx';
import NotAuthenticatedRoutes from '@/components/router/NotAuthenticatedRoutes.tsx';
import CreateDevicePage from '@/pages/CreateDevicePage.tsx';
import CreateParcelPage from '@/pages/CreateParcelPage.tsx';
import CreateUserPage from '@/pages/CreateUserPage.tsx';
import DevicesPage from '@/pages/DevicesPage.tsx';
import EditDevicePage from '@/pages/EditDevicePage.tsx';
import EditParcelPage from '@/pages/EditParcelPage.tsx';
import EditUserPage from '@/pages/EditUserPage.tsx';
import ForgotPasswordPage from '@/pages/ForgotPasswordPage.tsx';
import FruitsPage from '@/pages/FruitsPage.tsx';
import HomePage from '@/pages/HomePage.tsx';
import LoginPage from '@/pages/LoginPage.tsx';
import NotFoundPage from '@/pages/NotFoundPage.tsx';
import 'react-toastify/dist/ReactToastify.css';
import ParcelsPage from '@/pages/ParcelsPage.tsx';
import ResetPasswordPage from '@/pages/ResetPasswordPage.tsx';
import UsersPage from '@/pages/UsersPage.tsx';

function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route element={<AuthenticatedRoutes />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/users/create" element={<CreateUserPage />} />
            <Route path="/users/edit/:id" element={<EditUserPage />} />
            <Route path="/parcels" element={<ParcelsPage />} />
            <Route path="/parcels/create" element={<CreateParcelPage />} />
            <Route path="/parcels/edit/:id" element={<EditParcelPage />} />
            <Route path="/fruit-types" element={<FruitsPage />} />
            <Route path="/devices/:parcelId" element={<DevicesPage />} />
            <Route
              path="/devices/create/:parcelId"
              element={<CreateDevicePage />}
            />
            <Route
              path="/devices/edit/:deviceType/:parcelId/:deviceId"
              element={<EditDevicePage />}
            />
          </Route>
          <Route element={<NotAuthenticatedRoutes />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
