import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { CreateSortFormData } from '@/components/forms/AddSortForm.tsx';
import {
  addFruitSort,
  removeFruitSort,
  setFruitSorts,
} from '@/redux/fruitSort/fruitSort.slice.ts';
import {
  CreateFruitSortResponse,
  GetFruitSortsResponse,
} from '@/redux/fruitSort/fruitSort.types.ts';
import axiosServer from '@/services/axios.service.ts';

const getAllFruitSorts = createAsyncThunk<GetFruitSortsResponse, string>(
  'distributor/get-fruit-sorts',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetFruitSortsResponse>(
        `/distributor/fruit-sort?fruitTypeId=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setFruitSorts(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const createFruitSort = createAsyncThunk<
  CreateFruitSortResponse,
  CreateSortFormData
>('distributor/create-fruit-sort', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.post<CreateFruitSortResponse>(
      '/distributor/fruit-sort/create-fruit-sort',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(addFruitSort(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const deleteFruitSort = createAsyncThunk<
  CreateFruitSortResponse,
  { id: string }
>('distributor/delete-fruit-sort', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.delete<CreateFruitSortResponse>(
      `/distributor/fruit-sort/delete?id=${reqData.id}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(removeFruitSort(reqData.id));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

export { createFruitSort, getAllFruitSorts, deleteFruitSort };
