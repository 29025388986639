import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormDatePicker from '@/components/controls/CustomFormDatePicker.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import BillingAddressForm from '@/components/forms/user/BillingAddressForm.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { Spinner } from '@/components/ui/spinner.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { createUser } from '@/redux/users/users.actions.ts';
import { CreateUserResponse } from '@/redux/users/users.types.ts';
import { createUserSchema } from '@/zod/types.ts';

export type CreateUserFormData = {
  name: string;
  surname: string;
  email: string;
  password: string;
  phoneNumber: string;
  address: string;
  place: string;
  country: string;
  nameOfTheCompany: string;
  pib: string;
  mbr: string;
  dateOfPayment: Date;
  distributorId: string;
  differentBillingAddress: boolean;
  billingAddress: string;
  billingPlace: string;
  billingCountry: string;
};

const AddUserForm: FC = () => {
  const form = useForm({
    resolver: zodResolver(createUserSchema),
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      password: '',
      phoneNumber: '',
      address: '',
      place: '',
      country: '',
      nameOfTheCompany: '',
      pib: '',
      mbr: '',
      dateOfPayment: new Date(),
      distributorId: localStorage.getItem('id'),
      differentBillingAddress: false,
      billingAddress: '',
      billingPlace: '',
      billingCountry: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [requestSent, setRequestSent] = useState<boolean>(false);

  const handleErrorResponse = (response: CreateUserResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
    router('/users');
  };

  const onGiveUpClick = () => {
    router('/users');
  };

  const onSubmit = async (data: CreateUserFormData) => {
    setRequestSent(true);
    // @ts-ignore
    const response = await dispatch(createUser(data)).unwrap();
    setRequestSent(false);

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Korisnik je uspešno kreiran');
    router('/users');
  };

  if (requestSent) {
    return (
      <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full items-center justify-center">
        <Spinner size="medium" className="text-[#007bff]" />
      </div>
    );
  }

  return (
    <div className="flex w-[88%] flex-col bg-[#85b4f2] bg-opacity-10 max-md:w-full">
      <nav className="flex itemst-start bg-white border-b-2 w-full px-10 py-5 max-md:px-5">
        <div className="text-3xl font-bold">Dodaj novog korisnika</div>
      </nav>
      <Form
        reset={form.reset}
        formState={form.formState}
        clearErrors={form.clearErrors}
        control={form.control}
        getFieldState={form.getFieldState}
        getValues={form.getValues}
        handleSubmit={form.handleSubmit}
        register={form.register}
        resetField={form.resetField}
        setError={form.setError}
        setFocus={form.setFocus}
        setValue={form.setValue}
        trigger={form.trigger}
        unregister={form.unregister}
        watch={form.watch}
      >
        <form
          className="flex items-start gap-6 p-10 max-md:p-5"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="bg-white w-full shadow-lg">
            <nav className="flex itemst-start bg-white border-b-2 w-full px-5 py-3">
              <div className="text-xl font-bold">Novi korisnik</div>
            </nav>
            <div className="border-b border-gray-900/10 pt-5 px-5 text-sm">
              <div className=" flex flex-col">
                <CustomFormInput
                  customForm={form}
                  name="name"
                  labelText="Ime"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="surname"
                  labelText="Prezime"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="email"
                  labelText="Email"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="password"
                  labelText="Lozinka"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="password"
                />
                <CustomFormInput
                  customForm={form}
                  name="phoneNumber"
                  labelText="Telefon"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="address"
                  labelText="Adresa"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="place"
                  labelText="Mesto"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="country"
                  labelText="Drzava"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="nameOfTheCompany"
                  labelText="Naziv privrednog društva ili poljoprivrednog gazdinstva"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="pib"
                  labelText="PIB ili BGP"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormInput
                  customForm={form}
                  name="mbr"
                  labelText="Maticni broj firme ili JMBG"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  type="text"
                />
                <CustomFormDatePicker
                  customForm={form}
                  name="dateOfPayment"
                  labelText="Datum plaćanja pretplate"
                  formItemStyle="flex flex-row max-md:flex-col w-full border-b-2 border-dotted pb-10 py-10 max-md:py-5"
                  labelStyle="max-md:w-full w-[15%]"
                  divStyle="max-md:w-full w-[85%]"
                  buttonStyle="max-md:w-full w-full py-3 px-5"
                />
                <BillingAddressForm
                  form={form}
                  isFormShown={form.getValues(
                    'differentBillingAddress' as never
                  )}
                />
                <div className="flex flex-row max-md:flex-col w-full py-10">
                  <span className="max-md:w-full w-[15%]" />
                  <div className="w-[85%] max-md:w-full flex flex-row gap-5">
                    <Button
                      className="bg-gray-600 text-white py-3 px-2"
                      onClick={onGiveUpClick}
                    >
                      Odustani
                    </Button>
                    <Button
                      className="bg-[#007bff] py-3 px-2 text-white"
                      type="submit"
                    >
                      Dodaj korisnika
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default AddUserForm;
