import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { ForgotPasswordFormData } from '@/components/forms/ForgotPasswordForm.tsx';
import { LoginFormData } from '@/components/forms/LoginForm.tsx';
import { ResetPasswordFormData } from '@/components/forms/ResetPasswordForm.tsx';
import { login, setName } from '@/redux/auth/auth.slice.ts';
import { DefaultResponse } from '@/redux/auth/auth.types.ts';
import axiosServer from '@/services/axios.service.ts';

const distributorLogin = createAsyncThunk<DefaultResponse, LoginFormData>(
  'distributor/login',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.post(
        '/auth/distributor',
        reqData
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      const { token } = axiosResponse.headers;
      localStorage.setItem('token', token);
      localStorage.setItem('name', axiosResponse.data.content.name);
      localStorage.setItem('id', axiosResponse.data.content.id);
      dispatch(login());
      dispatch(
        setName({
          name: axiosResponse.data.content.name,
        })
      );
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const distributorForgotPassword = createAsyncThunk<
  DefaultResponse,
  ForgotPasswordFormData
>('distributor/forgot-password', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post(
      '/auth/distributor/forgot-password',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const distributorResetPassword = createAsyncThunk<
  DefaultResponse,
  ResetPasswordFormData
>('distributor/reset-password', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post(
      '/auth/distributor/reset-password',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

export {
  distributorLogin,
  distributorForgotPassword,
  distributorResetPassword,
};
