import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faHomeLg,
  faLeaf,
  faMap,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

type MobileNavigationLinkProps = {
  text: string;
  icon: IconProp;
  link: string;
};

const MobileNavigationLink: FC<MobileNavigationLinkProps> = ({
  text,
  link,
  icon,
}) => {
  const location = useLocation();
  const path = location.pathname;

  const isActive = () => {
    if (link === '/' && path === '/') {
      return true;
    }
    return link !== '/' && path.startsWith(link);
  };

  return (
    <Link
      to={link}
      className={`font-light flex flex-col items-center gap-1 ${isActive() ? 'text-[#33b35a]' : ''}`}
    >
      <FontAwesomeIcon
        icon={icon}
        className="w-5 h-5"
        color={isActive() ? '#33b35a' : ''}
      />
      {text}
    </Link>
  );
};

const MobileFooterNavigation: FC = () => {
  return (
    <footer className="flex flex-row w-full border-r-2 md:hidden sticky bottom-0 bg-white px-2 items-center justify-center min-h-16 shadow-[0px_-5px_10px_1px_#00000024]">
      <nav className="text-sm text-[#686a76] flex flex-row gap-5">
        <MobileNavigationLink text="Početna" icon={faHomeLg} link="/" />
        <MobileNavigationLink text="Korisnici" icon={faUsers} link="/users" />
        <MobileNavigationLink text="Parcele" icon={faMap} link="/parcels" />
        <MobileNavigationLink text="Vrste" icon={faLeaf} link="/fruit-types" />
      </nav>
    </footer>
  );
};

export default MobileFooterNavigation;
