import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { CreateFruitTypeFormData } from '@/components/forms/AddFruitTypeForm.tsx';
import { removeFruitSortsForRemovedType } from '@/redux/fruitSort/fruitSort.slice.ts';
import {
  addFruitType,
  removeFruitType,
  setFruitTypes,
} from '@/redux/fruitType/fruitType.slice.ts';
import {
  CreateFruitTypeResponse,
  GetFruitTypesResponse,
} from '@/redux/fruitType/fruitType.types.ts';
import axiosServer from '@/services/axios.service.ts';

const getAllFruitTypes = createAsyncThunk<GetFruitTypesResponse, void>(
  'distributor/get-fruit-types',
  async (_reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetFruitTypesResponse>(
        `/distributor/fruit-type`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setFruitTypes(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const createFruitType = createAsyncThunk<
  CreateFruitTypeResponse,
  CreateFruitTypeFormData
>('distributor/create-fruit-type', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.post<CreateFruitTypeResponse>(
      '/distributor/fruit-type/create-fruit-type',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(addFruitType(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const deleteFruitType = createAsyncThunk<
  CreateFruitTypeResponse,
  { id: string }
>('distributor/delete-fruit-type', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.delete<CreateFruitTypeResponse>(
      `/distributor/fruit-type/delete?id=${reqData.id}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(removeFruitType(reqData.id));
    dispatch(removeFruitSortsForRemovedType(reqData.id));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

export { createFruitType, getAllFruitTypes, deleteFruitType };
