import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import EditUserForm from '@/components/forms/EditUserForm.tsx';
import MobileFooterNavigation from '@/components/layout/MobileFooterNavigation.tsx';
import Navbar from '@/components/layout/Navbar.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { getUserById } from '@/redux/users/users.actions.ts';
import { CreateUserResponse, User } from '@/redux/users/users.types.ts';

const EditUserPage: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const router = useNavigate();
  const [userForEdit, setUserForEdit] = useState<User>({
    id: '',
    address: '',
    country: '',
    createdAt: '',
    createdBy: '',
    mbr: '',
    dateOfPayment: '',
    email: '',
    name: '',
    password: '',
    nameOfTheCompany: '',
    pib: '',
    phoneNumber: '',
    place: '',
    surname: '',
    differentBillingAddress: false,
    billingAddress: '',
    billingCountry: '',
    billingPlace: '',
  });

  const handleErrorResponse = (response: CreateUserResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchUser = async () => {
      // @ts-ignore
      const response = await dispatch(getUserById(params.id)).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }

      setUserForEdit(response.content);
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex  w-full flex-col">
      <Navbar />
      <div className="flex flex-1 flex-row">
        <Sidebar />
        <EditUserForm existingUser={userForEdit} />
      </div>
      <MobileFooterNavigation />
    </div>
  );
};

export default EditUserPage;
