import { FC } from 'react';

import LoginForm from '@/components/forms/LoginForm.tsx';

const LoginPage: FC = () => {
  return (
    <div className="h-screen">
      <div className="flex flex-1 flex-row w-screen justify-center items-center bg-[#343a40] h-full">
        <div className="flex flex-row max-md:flex-col max-md:items-center self-center md:min-w-[1000px] max-md:mx-5 max-md:w-full md:min-h-[800px]">
          <div className="flex flex-col justify-center pl-5 bg-[#007bff] md:w-[50%] max-md:p-5">
            <h1 className="text-4xl text-white font-bold">
              Smart Watering Distributer
            </h1>
            <p className="text-lg text-white font-light max-md:hidden">
              Dobrodošli, unesite Vaše podatke sa desne strane.
            </p>
          </div>
          <div className="md:w-[50%] max-md:w-full bg-white flex justify-center items-center">
            <LoginForm />
          </div>
        </div>
      </div>
      <footer className="sticky bottom-0 bg-[#343a40] h-70px text-white text-center">
        <span>© 2024 Smart Watering</span>
      </footer>
    </div>
  );
};

export default LoginPage;
